exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cell-line-index-tsx": () => import("./../../../src/pages/cell-line/index.tsx" /* webpackChunkName: "component---src-pages-cell-line-index-tsx" */),
  "component---src-pages-diseases-index-tsx": () => import("./../../../src/pages/diseases/index.tsx" /* webpackChunkName: "component---src-pages-diseases-index-tsx" */),
  "component---src-templates-cell-line-tsx": () => import("./../../../src/templates/cell-line.tsx" /* webpackChunkName: "component---src-templates-cell-line-tsx" */),
  "component---src-templates-disease-catalog-tsx": () => import("./../../../src/templates/disease-catalog.tsx" /* webpackChunkName: "component---src-templates-disease-catalog-tsx" */),
  "component---src-templates-disease-cell-line-tsx": () => import("./../../../src/templates/disease-cell-line.tsx" /* webpackChunkName: "component---src-templates-disease-cell-line-tsx" */),
  "component---src-templates-disease-tsx": () => import("./../../../src/templates/disease.tsx" /* webpackChunkName: "component---src-templates-disease-tsx" */),
  "component---src-templates-gene-name-tsx": () => import("./../../../src/templates/gene-name.tsx" /* webpackChunkName: "component---src-templates-gene-name-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */)
}

